// stylelint-disable declaration-no-important

//
// Text
//

.text-monospace { font-family: $font-family-monospace; }

// Alignment

.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate { @include text-truncate; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }
.text-underline { text-decoration: underline !important; }

// Weight and italics

.font-weight-light  { font-weight: $font-weight-light !important; }
.font-weight-normal { font-weight: $font-weight-normal !important; }
.font-weight-strong { font-weight: $font-weight-strong !important; }
.font-weight-bold   { font-weight: $font-weight-bold !important; }
.font-italic        { font-style: italic !important; }

// Few Font sizes

.font-10 {font-size: 0.625rem !important} // 10px
.font-11 {font-size: 0.688rem !important} // 11px
.font-12 {font-size: 0.75rem !important} // 12px
.font-13 {font-size: 0.813rem !important} // 13px
.font-14 {font-size: 0.875rem !important} // 14px
.font-15 {font-size: 0.983rem !important} // 15px
.font-16 {font-size: 1rem !important} // 16px
.font-17 {font-size: 1.063rem !important} // 17px
.font-18 {font-size: 1.125rem !important} // 18px
.font-20 {font-size: 1.25rem !important} // 20px
.font-26 {font-size: 1.625rem !important} // 26px
.font-30 {font-size: 1.875rem !important} // 30px
.font-36 {font-size: 2.25rem !important} // 36px
.font-40 {font-size: 2.5rem !important} // 40px

// Contextual colors

.text-white { color: $white !important; }

@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

.text-body { color: $body-color !important; }
.text-muted { color: $text-muted !important; }

.text-black-50 { color: rgba($black, .5) !important; }
.text-white-50 { color: rgba($white, .5) !important; }

// Misc

.text-hide {
  @include text-hide($ignore-warning: true);
}
