//
// Layout content styles
//

html {
    position: relative;
    min-height: 100%;
}
body {
    margin: 0;
    /* bottom = footer height */
}
.page-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: #f0f1f8;
	height: -webkit-fill-available;
}
.content-wrapper {
	display: flex;
	flex-direction: row;
	flex: 1 0 auto;
	position: relative;
}
.content-area {
	flex: 1 auto;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	position: relative;
	background-color: #f2f3fa;
	padding-left: $sidebar-width;
	padding-top: $navbar-height;
	transition: margin .2s ease-in-out;
	// height: 100vh;
	min-height: -webkit-fill-available;
	align-items: center; // CUSTOM FIX FOR HD SCREENS
}
.page-content {
	padding: $page-content-padding;
	position: relative;
	flex: 1 1 0%;
	display: flex;
	flex-direction: column;
	// max-width: 1290px; // CUSTOM FIX FOR HD SCREENS
	width: -webkit-fill-available; // CUSTOM FIX FOR HD SCREENS
	.content-top-heading {
		margin-top: -$page-content-padding;
		margin-left: -$page-content-padding;
		margin-right: -$page-content-padding;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 0px;
		padding-right: 0px;
		padding-bottom: 0px;
		padding-top:0px;
		.content-top-heading {
			margin-top: -20px;
			margin-left: -20px;
			margin-right: -20px;
		}
	}
}

.page-content-buscadores {
	background-color: #f2f3fa;
	background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23b4b4b4' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	padding: $page-content-padding;
	position: relative;
	flex: 1 1 0%;
	display: flex;
	flex-direction: column;
	.content-top-heading {
		margin-top: -$page-content-padding;
		margin-left: -$page-content-padding;
		margin-right: -$page-content-padding;
	}
	@include media-breakpoint-down(sm) {
		padding: 20px;
		.content-top-heading {
			margin-top: -20px;
			margin-left: -20px;
			margin-right: -20px;
		}
	}
}

//
// PAGE HEADING, TITLE
//

.page-heading {
	padding-bottom: 30px;
	display: flex;
	align-items: center;
	.page-title {
		font-size: 1.5rem;
		margin: 0;
	}
	.page-title-sep {
		margin-right: 25px;
		padding-right: 25px;
		border-right: 1px solid #E4E5EC;
		font-weight: 200;
	}
	.breadcrumb {
		padding: 0;
		margin: 0;
		font-size: 13px;
		background-color: inherit;
		display: flex;
		align-items: center;
		li {
			float: none;
			color: #868e96;
			a {
				color: inherit;
			}
			& + li:before {
				content: '\002022';
				color: inherit;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		.page-title-sep {
			margin-right: 5px;
			padding-right: 5px;
			border-right: 1px solid #E4E5EC;
		}
		/*.breadcrumb {
			display: none;
		}*/
	}
}

body.mini-sidebar {
	.content-area {
		padding-left: $sidebar-mini-width;
	}
}
