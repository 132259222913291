// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
  color: color-yiq($background);
  @include gradient-bg($background);
  border-color: $border;

  @include hover {
    color: color-yiq($hover-background);
    @include gradient-bg($hover-background);
    border-color: $hover-background;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($hover-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
    border-color: $active-background;
  }
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $hover-background: $color, $hover-border: $color, $active-background: darken($color, 7.5%), $active-border: darken($color, 7.5%)) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  &:hover {
    color: $color-hover;
    background-color: $hover-background;
    border-color: $hover-border;
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $color-hover; //color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @if $enable-rounded {
    border-radius: $border-radius;
  } @else {
    border-radius: 0;
  }
}


// Social buttons
@mixin social-button-variant($name, $background, $color: #fff) {
    .btn-#{$name} {
        background-color: $background;
        color: $color;
        &.btn-link {
          color: $background;
          background-color: transparent;
        }
        &:not(.btn-link):hover,
        &:not(.btn-link):not(:disabled):not(.disabled).active,
        {
          color: $color;
          background-color: darken($background, 7.5%);
        }
    }
}
