// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  // padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius($badge-border-radius);

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}


// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}


//
// Circle badges
//

.badge-circle {
    min-height: 22px;
    min-width: 22px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 50%;
}


//
// Point badges
//

.badge-point {
    display: inline-block !important;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    padding: 0;
}

.avatar-badge {
    position: absolute;
    right: 0;
    bottom: 0;
    border: 2px solid #f5f5f5;
    height: 12px;
    width: 12px;
    border-radius: 50%;
}


// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

//
// Outline badges
//

.badge-outlined {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius($badge-border-radius);
  border: solid 1px;
  color: #ff6347;
  border: 1px solid #ff6347;
  width: fit-content;

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}
