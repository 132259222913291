// Theme Core Styles (components, layout, ...)
@import 'theme/scss/core.scss';

//
// CUSTOM LOADER
//
body, html {
    height: 100%;
    overflow-x: hidden;
    color:#333;
  }

.app-loading {
    .logo {
      width: 474px;
      height: 56px;
      // this way asset gets processed by webpack 
      background-image: url(./assets/images/logos/logo_loader.gif);
      background-size: 474px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .app-loading {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  /* .app-loading .spinner {
    height: 200px;
    width: 200px;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .app-loading .spinner .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    // stroke: $primary;
    stroke: #ddd;
  } */

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

/// 
/// Page Content
/// 

a {
    text-decoration: none !important;
    color: inherit !important;
}
input:focus-visible,
select:focus-visible,
textarea:focus-visible {
  outline: none !important;
  box-shadow: 0px 0px 8px rgba(0, 153, 219, 0.658);
  -webkit-box-shadow: 0px 0px 8px rgba(0, 153, 219, 0.658);
  -moz-box-shadow: 0px 0px 8px rgba(0, 153, 219, 0.658);
  -o-box-shadow: 0px 0px 8px rgba(0, 153, 219, 0.658);
  -ms-box-shadow: 0px 0px 8px rgba(0, 153, 219, 0.658);
}
.page-content{
    background-color: #ffffff;
}

body{
    font-family: $font-family-base;
}
h1, h2, h3, h4, h5{
    font-family: $font-family-base;
}

.text-align-right{
  text-align: right;
}

.text-align-center{
  text-align: center;
}
/// 
/// Header & Nav
/// 

#header{
  height:100px;

  .navbar-custom {
    i{
      color: white;
      font-size: 32px;
    }
  }
}



.logo{
    img{
        display: block;
        margin: auto;
        margin-left: 0;
        width: 12rem;
        max-width: 100%;
        max-height: 100%;
        width: 280px;
    }
}
.topnav {
    background-color: #eee;
    width: 100%;
    position: relative;
}

.col-md-15{
  width: 20%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

}

@media only screen and (max-width: 576px) {
    .topnav {
        text-align: center !important;
    }
}

.social-topnav{
    justify-content: flex-end;
}

.social-topnav a:hover{
    color: red !important;
}

.phone-wrapper:hover a,
.phone-wrapper:hover i{
    text-decoration: none;
    color: red !important;
}

.phone-wrapper{
    font-family: $font-family-base;
    a{
        font-size: 0.9rem;
        font-weight: 400;
        span{
            color:$primary;
            font-weight: 400;
        }
    }
}

@media only screen and (max-width: 576px) {
    .social-topnav {
        justify-content: center !important;
    }
}

.custom-menu{
    text-align: right;
}

@media only screen and (max-width: 768px) {
    .custom-menu{
        text-align: center;
    }
}

.top-wrapper{
    background: rgb(255,255,255);
    padding-top: 2rem;
    padding-bottom: 0.2rem;
}



.slider{

  height: 500px;
  padding-top: 90px;
  background-position-x: center;
  background-repeat: no-repeat;

  .content-slider{
      color: #ffffff;
      .content-slider-info{
          width: 50%;
          float:left;
      }

      .content-slider-img{
        width: 50%;
        float:left;
        text-align: right;
      }

      .content-slider-img img{
        width: 80%;
      }
  
      font-family: 'Montserrat', sans-serif; 
      h2{
          font-family: 'Montserrat', sans-serif; 
          font-size: 41px;
          font-weight: 300;
      }
      p{
          font-size: 23px;
          font-weight: 100;
          padding: 25px 0px;
      }
  }
}

@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1250px !important;
      padding: 0px !important;
  }

  .subcontainer{
    width: 1250px;
    margin: 0 auto;
  }
}

.height-100-porciento {
  height:100%;
}

img{
  width: 100%;
}

.width-50{
  width: 50% !important;
}

.width-80{
  width: 80% !important;
}
.button{
    border-radius: 33px;
    height: 45px;
    width: 230px;
    border: 0px !important;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat'; 
}

.btn-wihte{
  color: $color-principal !important;
  background: #ffffff !important;
  border: 1px solid #ffffff !important;
}

.btn-wihte:hover{
  color: #ffffff !important;
  background: $color-principal !important;
  border: 1px solid #ffffff !important;
}

.btn-blue{
  
  color: #ffffff !important;
  background: $color-principal !important;
  border: 1px solid #ffffff !important;
 
}

.btn-blue:hover{
  color: $color-principal !important;
  background: #ffffff !important;
  border: 1px solid $color-principal !important;
 
}

.input{
  border-radius: 22px;
  height: 45px;
  border: 1px solid $color-principal;
  padding-left: 20px;
  width: 100%;
}

.full-width{
  width: 100%;
}

.title {
  h2{
    font-family: 'Montserrat'; 
    color: $color-principal;
    font-size: 34px;
    text-align: center;
    font-weight: 300 !important;
  }

  h4{
    font-family: 'Montserrat'; 
    text-align: center;
    font-weight: 300 !important;
  }
}

.color-principal{
  color: $color-principal !important;
}


.pt-6{
  padding-top: 6rem !important;
}

.pb-6{
  padding-bottom: 6rem !important;
}


.pt-7{
  padding-top: 7rem !important;
}

.pb-7{
  padding-bottom: 7rem !important;
}


.mb-6{
  margin-bottom: 6rem !important;
}

.mt-6{
  margin-top: 4rem !important;
}

.clientes-opinan{
  border-top: 1px solid $color-principal;
  .content-opinion{

      .icono-opinion{
          width: 65px;
          float: left;
         
          img{
              border-radius: 100%;
          }
      }
      .texto-opinion{

          margin-left: 75px;
          

          h5{
              color: $color-principal;
              font-family: 'Montserrat';
              font-weight: 600;

          }

          p{
              color: #333;
              font-family: 'Montserrat';
              font-size: 12px;
              font-weight: 500;
          }
      }
  }
}

.draw-images{
  display:inline;
}

.section-home-beneficios{
  .select-option{
      width:100%;
      margin-bottom: 45px;
      position: relative;

      .number-item{
          background: #009bdb;
          width: 40px;
          height: 40px;
          border-radius: 100%;
          padding-top: 5px;
          padding-left: 9px;
          font-size: 21px;
          font-weight: 100;
          color: #ffffff;
          float:left;
      }
      .title-item{
          font-family: 'Montserrat';
          font-size: 19px;
          color: #999999;
          font-weight: 500;
          padding-top: 4px;
          margin-left: 55px;
      }

      .text-item{
          margin-left: 20px;
          margin-top: 8px;
          padding-left: 35px;
          border-left: 1px solid #009bdb;
          font-size: 13px;
          padding-right: 50px;

      }
      .icon-item{
          position: absolute;
          top: 0px;
          right: 30px;
          color:#009bdb !important;
      }

  }
}

.section-home-newsletter{
   
    
  padding-left: 15px;
  padding-right: 15px;

  .title{
      border-top: 1px solid #009bdb;   
  }
  .input{
      width: 65%;
      float: left;
  }

  .suscribite{
      width: 35%;
      
  }
  .button{
      margin-left: 0px !important;
  }

}


.textarea{
  padding-top: 12px;
}

.aviso-text{
  color:red;
  display:inline !important;
}
.aviso-input{
  border-color:red;
}

.position-absolute-25bt{
  position: absolute;
  bottom: -25px;
}

.hidden{
  display:none;
}

.color-principal{
  color:$primary;
}

.width-100{
  width: 100px !important;
}

@media (max-width: 1200px){
 
  .section-quienes-somos-equipo .descripcion{
    height: 310px !important;
  }

  .info-graficos{
     img{
        width: 80% !important;
        padding-bottom: 85px;
    }
  }

}

@media (max-width: 992px){

  .col-md-15{
    width: 30% !important;
  }

  .content-slider-info{
    width: 100% !important;
  }
  .logo img{
    margin: 0 auto;
  }

  .section-quienes-somos-equipo .descripcion{
    height: 550px !important;
  }

  .section-home-beneficios .select-option .icon-item{
    right: 0px !important;
  }

  .draw-images{
    display:none;
  }

}

@media (max-width: 768px){

  .col-md-15{
    width: 50% !important;
  }

  #header{
    height:105px;

    .header-wrapper{
      .container{
        .row{
          padding-bottom: 7px;
        }
      }
    }

  }

  .logo img{
    margin-top: 5px !important;
  }

  .formulario-contacto{
    .seccion-2{
      margin-top: 0px !important;
    }
  }
  .partners .col-md-3{
    text-align: center;
    
  }


  .section-home-newsletter{
    form{
      margin-bottom: 25px;
    }
  }

  .slider .content-slider p {
    font-size: 18px !important; 
  }

  .slider .content-slider h2 {
    font-size: 32px !important; 
  }
  .content-slider-info .btn-wihte{
    margin-top: 25px !important; 

  }
  .content-slider-info .btn-blue{
    margin-top: 25px !important; 
  }


  .section-home-contact {
    p {
      font-size: 16px;
      text-align: center !important;
    }
  }
  .col-md-5{
      text-align: center !important;
  }

  .section-home-motores h2{
     text-align: center !important;
  }
  .section-home-motores .col-md-4{
    text-align: center !important;
  }

  .section-home-sistemas-reservas .col-md-3{
   padding-bottom: 20px !important; 
   border-left: 0px !important; 
  }

  .section-home-sistemas-reservas .col-md-3::before {
    display: none !important; 
  }

  .content-caracteristica{
    margin-top: 35px !important; 
  }


  /* .section-home-productos-contratos .col-md-4{
    padding-right: 52px;
  } */
  
  .row-logos{
    text-align: center;
  }
 
  .section-quienes-somos-equipo .descripcion{
    height: 310px !important;
  }

 /*  .section-home-beneficios .select-option .icon-item{
    right: -45px !important;
  } */

  .formulario-contacto .input{
    margin-top: 15px !important;
  }

  .formulario-contacto .mt-5{
    margin-top: 0px !important;
  }

  .data-text .col-md-4{
    margin-bottom: 15px !important;
  }

  .formulario-contacto .adjuntar{
    margin-top: 16px !important;
  }
}




@media (max-width: 640px){}
@media (max-width: 574px){
  
  .footer-content{
    text-align: center !important;
  }

  .section-quienes-somos-equipo .descripcion{
    height: 125px !important;

  }

  .texto-quienes-somos .row{
    margin-left: 0px !important;
    margin-right: 0px !important;

    .col-md-6{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
}

@media (max-width: 512px){

 
  .info-graficos{
    img{
       width: 90% !important;
       padding-bottom: 15px !important;
   }
 }

  .col-md-15{
    width: 100% !important;
  }

  .faq .row{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .faq .section-home-beneficios .select-option .title-item{
    font-size: 15px;
  }



  .slider-faqs{
    background-color: #009bdb;
    .content-slider-info{
      h2{
        color: #fff !important;
      }
    }
    
  }

  .trabaja-con-nosotros{
    background-color: #009bdb;
    .content-slider-info{
      h2{
        color: #fff !important;
      }
    }
    
  }

  .section-home-newsletter{
    .input{
      width: 100% !important;
    }
    .suscribite{
      width: 100% !important;
    }

    form{
      margin-bottom: 25px;
    }
  }
  
  .slider{
    height: 400px !important;
    padding-top: 0px !important;
    background-image: none !important;
    .content-slider-info {
      margin-top: 35px !important;
      text-align: center;
    }
  }
 
  .slider-quienes-somos{
    height: 180px !important;
  }

  .slider-partners-somos{
    background-color: #009bdb;
    height: 300px !important;
    
    .content-slider-info{
      h2{
        color: #fff !important;
        margin-bottom: 50px !important;
      }
    }
    
  }
  
  .slider-faqs{
    height: 250px !important;
  }

  .slider-pricing{
    height: 225px !important;
  }


  .content-slider-info {

    p{
      display: none !important;
    }

    h2 {
      font-size: 18px !important; 
      color: #009bdb;
      text-align: center;
    }

    .btn-wihte{
      margin-left: 0px !important;
      padding-left: 0px !important;
      margin-top: 0px !important; 
    }
    .btn-blue{
      margin-left: 0px !important;
      padding-left: 0px !important;
      margin-top: 10px !important;
    }
  }

}
@media (max-width: 411px){


  .section-utilidades{
  

    .row {
        .col-md-4{



            h4{
              padding-bottom: 15px !important;
            }
            img{
                
            }
            p{
              margin-bottom: 60px !important; 
            }
        }
    }
 
}

 .logo{
   width: 100%;
   float: left;
   img{
    width: 70%;
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 10px !important;
    margin-top: 12px;
   }
 }

 .section-home-beneficios{

    .select-option{

        .title-item{
          font-size: 16px !important;
        }
    }
}



 .navbar-custom-wrapper{
   margin-right: 0px !important;
   padding-right: 0px !important;
 }

 .header-wrapper{
   .height-100-porciento{
    margin-right: 0px !important;
    padding-right: 0px !important;

    .row{
      .navbar-custom{
        margin: 0 auto;
        padding-left: 0px;
      }
      .col-sm-12{
        .col-auto{
          padding-right: 7px;
          padding-left: 7px;

          a{
            i{
              font-size: 30px !important;
            }
          }
        }
      }
    }
   }
 }

 .redes-sociales-footer{
  margin-top: 5px !important;
  margin-bottom: 5px !important;
 }
}
.content-slider-info {
  @media (max-width: 411px){
    width: 100%;
    text-align: center;
      button{
          margin: 0 auto 10px;
      }
  }
}
.section-home-contact {
  @media (max-width: 411px){
    text-align: center;
    div{
        .ml-4{
        margin-left: 0px !important;
      }
    }
  }
}
.section-home-productos-contratos{
  @media (max-width: 768px){
    div{
      .col-4{
        width: 100% !important;
        max-width: 100% !important;
        flex: none;
      }
    }
  }
}
