//
// Theme & layout variables
//

$navbar-height: 66px;

$sidebar-width: 255px;
$sidebar-mini-width: 65px;
$sidebar-bg: #3d454e;/*#2C2F48;*/
$sidebar-active-item-bg: #1663FF;

$page-content-padding: 0;
