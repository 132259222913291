//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  margin-bottom: $card-margin-bottom;
  /* @include box-shadow($card-box-shadow); */
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }

  &.card-fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      z-index: ($zindex-backdrop + 5);
      height: auto !important;
  }
  &.card-fullheight {
      height: calc(100% - #{$card-margin-bottom});
  }
}
body.fullscreen-mode {
    overflow-y: hidden;
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-x;
  .card-fullwidth-block {
      margin-left: -$card-spacer-x;
      margin-right: -$card-spacer-x;
  }
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -($card-spacer-y / 2);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  @include hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

// Custom
.box-title {
  margin-bottom: 2rem;
  font-size: 1.3rem;
}


//
// Optional textual caps
//
.card-header, .card-cup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $card-spacer-x;
  min-height: $card-head-height;
}
.card-header {
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
  .box-title {
    margin-bottom: 0;
  }
  .card-actions {
      position: relative;
      & > a {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          color: #aaa;
          border-radius: 50%;
          transition: all ease-in-out .15s;
          cursor: pointer;
          &:hover {
              color:inherit;
              background-color: #eee;
          }
      }
  }
  .tabs-line, .nav-pills {margin:0}
  .line-tabs {
      min-height: $card-head-height;
      .nav-link {
          height: 100%;
          display: inline-flex;
          align-items: center;
      }
  }
  .nav-tabs {
      min-height: $card-head-height;
      align-items: flex-end;
      margin-bottom: -1px;
  }
  &.card-header-dark {
      .box-title, .card-title {color: #fff}
      .card-actions > a {
          color: #fff;
          &:hover {
              background-color: rgba(255,255,255,.4);
              color: #fff;
          }
      }
  }
}

.card-cup {
  border-radius: $border-radius;
  margin-left: 4%;
  margin-right: 4%;
  margin-top: -($card-head-height / 2);
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}


// 
// Dark Card style
//

.card.card-dark {
  .box-title, .card-title {color: #fff}
  .card-header {
    .card-actions > a {
        color: #fff;
        &:hover {
            background-color: rgba(255,255,255,.4);
            color: #fff;
        }
    }
  }
}



//
// Header navs
//

.card-header-tabs {
  margin-right: -($card-spacer-x / 2);
  margin-bottom: -$card-spacer-y;
  margin-left: -($card-spacer-x / 2);
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -($card-spacer-x / 2);
  margin-left: -($card-spacer-x / 2);
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-radius($card-inner-border-radius);
}

// Card image caps
.card-img-top {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-top-radius($card-inner-border-radius);
}

.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-bottom-radius($card-inner-border-radius);
}


// Card deck

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      display: flex;
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}


//
// Card groups
//

.card-group {
  display: flex;
  flex-direction: column;

  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:first-child {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            border-bottom-right-radius: 0;
          }
        }

        &:last-child {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            border-bottom-left-radius: 0;
          }
        }

        &:only-child {
          @include border-radius($card-border-radius);

          .card-img-top,
          .card-header {
            @include border-top-radius($card-border-radius);
          }
          .card-img-bottom,
          .card-footer {
            @include border-bottom-radius($card-border-radius);
          }
        }

        &:not(:first-child):not(:last-child):not(:only-child) {
          @include border-radius(0);

          .card-img-top,
          .card-img-bottom,
          .card-header,
          .card-footer {
            @include border-radius(0);
          }
        }
      }
    }
  }
}


//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}


//
// Accordion
//

.accordion {
  .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
  }

  .card:not(:first-of-type) {
    .card-header:first-child {
      border-radius: 0;
    }
  }

  .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
