//
// Animations
//

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes collapseMenu {
    from {
        width: 60px;
    }

    to {
        width: 100%;
    }
}


@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0; }
  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3; }
  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5; }
  100% {
    -webkit-transform: scale(1);
    opacity: 0.0; } 
}

@-webkit-keyframes loader-rotate {
  to {
    transform: rotate(360deg); 
  } 
}

.fade-in-up {
    animation: fadeInUp .5s;
}

