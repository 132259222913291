// 
// Backdrop
//

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
    background-color: rgba(0, 0, 0, .5) !important;
	transition: opacity .3s cubic-bezier(.4,0,.2,1);
	z-index: $zindex-backdrop;
	pointer-events: none;
}

.shined {
	z-index: ($zindex-backdrop + 1) !important;
}

body.has-backdrop {
	.backdrop {
	    pointer-events: auto;
	    opacity: 1;
	}
}